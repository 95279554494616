<template>
  <div class="flex p-20">
    <client-ratings :client-id="clientId" />
  </div>
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import PageMixin from '../mixins/Page'
import PublicGatewayPage from '../mixins/PublicGatewayPage'
import ClientRatings from '@/components/bodies/ClientRatings.vue'

export default {
  components: { ClientRatings },
  mixins: [PageMixin, PublicGatewayPage],
  setup() {
    const route = useRouter()
    const loading = ref(true)

    const clientId = computed(() => route.currentRoute.value.params.clientId)

    const fetchCompanyData = async () => {
      loading.value = false
    }

    onMounted(async () => {
      await fetchCompanyData()
    })

    return {
      loading,
      clientId
    }
  }
}
</script>
